import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const AndroidFacebookAlert = () => {
  // State to control the modal visibility.
  const [showModal, setShowModal] = useState(false);

  // Function to detect if the user agent is Android.
  const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
  };

  // Function to detect if the browser is Facebook In-App Browser.
  const isFacebookInAppBrowser = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    // Optionally: you can log or display the user agent somewhere if needed.
    // For example: console.log("User Agent:", ua);
    return ua.includes("AI Build") ||
           ua.includes("wv") ||
           ua.includes("[FB_IAB") ||
           ua.includes("FBAV");
  };

  // Check on component mount whether to show the modal.
  useEffect(() => {
    if (isAndroid() && isFacebookInAppBrowser()) {
      setShowModal(true);
    }
  }, []);

  // Handler to close the modal.
  const handleClose = () => setShowModal(false);

  return (
    <div>
      {/* Optional: Display the user agent (hidden by default) */}
      <div id="user-agent" style={{ display: 'none' }}>
        {navigator.userAgent}
      </div>

      {/* Modal that shows when conditions are met */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Open in Browser</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          It looks like you are using the In-App Browser on Android.
          For a better experience, please open this page in your device's browser.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AndroidFacebookAlert;
