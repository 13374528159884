import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import thankyou_icon from "../../Images/thnku-icon.png";
import thankyou_text from "../../Images/thnku-text.png";
import FacebookPixel from '../../MetaComponents/Facebookpixel';
import { sendGiftEmailToUser, subscribeUserToSchedule, getPlans } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import connection from '../../config/connection';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function ThankyouPaymentUpgrade(props) {
    const auth = useSelector((state) => state);
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const recipentEmail = sessionStorage.getItem("giftRecipent");
    const [baseName, setBaseName] = useState('home');
    const [basePath, setBasePath] = useState(connection.homePage);


    const urlParams = new URLSearchParams(window.location.search);

    // Extract the 'receipt_name' parameter
    const receiptName = urlParams.get('receipt_name');
    const lookup_key = urlParams.get('lookup_key');
    const amount = urlParams.get('amount');
    const cat = urlParams.get('cat');

    //alert(receiptName);  // Outputs 'gift06nov01@yo

    const handleDefaultBehaviour = (filteredPlan) => {
        let subscriptionPayload = JSON.parse(sessionStorage.getItem("subscribeObj")) || {};
        //const subscriptionPayload =JSON.parse(sessionStorage.getItem("subscribeObj")) 
        //if(subscriptionPayload){
        if (subscriptionPayload && Object.keys(subscriptionPayload).length !== 0) {

            subscriptionPayload.recipient_email = recipentEmail;
            if (splitLocation[1] == 'dating') {
                subscriptionPayload.plan_type = 'Dating';
            }
            else {
                subscriptionPayload.plan_type = 'Legacy';
            }
            // subscriptionPayload.plan_type = 'Dating';  // Add plan_type here

        }
        else {
            subscriptionPayload.recipient_email = receiptName;
            if (splitLocation[1] == 'dating') {
                subscriptionPayload.plan_type = 'Dating';
            }
            else {
                subscriptionPayload.plan_type = 'Legacy';
            }
        }
        let token = false
        if (auth.auth.loginUserToken != "" || sessionStorage.getItem("token")) {
            token = true
        }
        // console.log('subscriptionPayload',subscriptionPayload);
        //subscriptionPayload.lookup_key = filteredPlan.;
        subscriptionPayload.plan_type = 'Dating';

        subscriptionPayload.plan_id = filteredPlan.plan_id;
        subscriptionPayload.cat_id = filteredPlan.cat_id;
        subscriptionPayload.price_id = filteredPlan.price_id;
        //   subscriptionPayload.recipient_email = receiptName;
       // alert(token)
        dispatch(subscribeUserToSchedule(subscriptionPayload, auth.auth.loginUserToken, (res) => {
            sessionStorage.removeItem("subscribeObj")
            window.location.href = "/dating/paymentThankyou/?success=true";

            /*  dispatch(sendGiftEmailToUser({"recipient_email":receiptName,"baseName":splitLocation[1]}, (res) => {
                 sessionStorage.removeItem("giftRecipent")
                 window.location.href = "/dating/GiftThankyou/?success=true";
               }));  */

        }));

    }

    const getAllPlan = () => {






        dispatch(getPlans(auth.auth.loginUserToken, (result) => {


            if (result?.data) {
               // console.log('getAllPlan', result?.data)
                const planByCategory = result?.data?.filter(curr => (curr.category.name == 'Dating' && curr.id ==lookup_key))
               // console.log('planByCategory', planByCategory)
                const plansWithParent = result?.data?.filter(curr => (curr.parent_plan))
                const resp = planByCategory[0];
                 console.log('resp', resp)

                const filteredPlan = {
                    cat_id: resp.category.id,
                    plan_id:resp.plans[0]?.plan,
                    price_id:resp.plans[0].id
                   }
                   console.log('filteredPlan', filteredPlan)

                   handleDefaultBehaviour(filteredPlan);
               // const plans = result?.data?.filter(curr => (curr.parent_plan == null))
              //  this.setState({ allPlans: plansWithParent, renewalPlans: plans, planByCategory: planByCategory })
            }
        }
        ))
    }

    useEffect(() => {
        if (receiptName) {
            // handleDefaultBehaviour()
            getAllPlan()
        }
    }, [])

    useEffect(() => {

        if (splitLocation[1] == 'dating') {

            setBaseName('dating');
            setBasePath(connection.datingHomePage);
        }
        else {
            setBaseName('home');
            setBasePath(connection.homePage);
        }
    }, []);


    return (
        <>
            <FacebookPixel trackName="Purchase" obj={{ value: 60, currency: "USD" }} />
            <section class="thankyou-outer">
                <div class="container inner-container">
                    <div class="row">
                        <div class="col-md-8 offset-md-2 col-sm-12">
                            <div class="login-inner thankyou-outer">
                                <div class="thankyou-upper">
                                    <img src={thankyou_icon} alt="" />
                                </div>
                                <div class="thankyou-bottom">
                                    <img src={thankyou_text} alt="" />
                                    <p class="p-text">Thank you. Your plan has been upgraded from monthly to annual.</p>
                                    <Link style={{ cursor: "pointer" }} onClick={() => { window.location.href = auth.auth.loginUserToken ? basePath + `?access_token=${auth.auth.loginUserToken}` : basePath }}>
                                        <p style={{ cursor: "pointer" }} className="new_user5">
                                            <a
                                                className="Puchase_now_btn_new"
                                                style={{ cursor: "pointer" }}
                                            >
                                                Continue
                                            </a>
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ThankyouPaymentUpgrade;
